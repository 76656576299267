@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OswaldRegular';
  src: url('./fonts/Oswald-Regular.ttf') format('truetype');
}

header {
  /* Information */
  background-color: #292929;
  vertical-align: middle;
  font-size: 200%;
  padding: auto;
  /* Positioning */
  position: relative;
  margin: 0 auto;
  width: 1002px;
  height: 100px;
}

hr {
  color: #262626;
}

nav {
  /* Information */
  background-color: #292929;
  text-align: center;
  color: #999;
  /* font-family: 'Oswald', sans-serif; */

  /* Positioning */
  width: 1000px;
  height: 25px;
  padding-left: 15px;
  margin: 0 auto;
  position: relative;
}

/* -----CONTENT----- */

body {
	width: 100%;
	color: #666;
	background-color: #292929;
}

.wrapper {
	/* Information */
	width: 1002px;
	height: 700px;
	background-color: #292929;

	/* Positioning */
	position: relative;
	margin: 0 auto;
}

.content1 {
  width: 427px;
  height: 450px;
  margin-left: 15px;
  margin-right: 3px;
  background-color: #292929;
  margin-top: 1%;
  position: absolute;
  /* font-family: 'Oswald', sans-serif; */
  color: #999;
}

.content4 {
  width: 850px;
  height: 500px;
  margin-top: 1%;
  margin-left: 7%;
  margin-right: 3px;
  background-color: #292929;
  position: absolute;
  /* font-family: 'Oswald', sans-serif; */
  color: #999;
}

.content2 {
  width: 127px;
  height: 450px;
  margin-left: 439px;
  margin-right: 3px;
  background-color: #292929;
  margin-top: 2%;
  position: absolute;
  padding-left: 5px;
}

.content3 {
  width: 427px;
  height: 450px;
  margin-left: 578px;
  background-color: #292929;
  margin-top: 1%;
  position: absolute;
}

.aktuelles1 {
  width: 1002px;
  height: 900px;
  margin-left: 5%;
  margin-right: 3px;
  background-color: #292929;
  margin-top: 4%;
  position: relative;
}

.aktuelles2 {
  width: 1002px;
  height: 450px;
  margin-left: 0px;
  margin-right: 3px;
  background-color: #292929;
  margin-top: 15px;
  position: relative;
}

.personlich1 {
  width: 410px;
  height: fit-content;
  margin-left: 30px;
  margin-right: 3px;
  background-color: #292929;
  margin-top: 55px;
  position: absolute;
  /* font-family: 'Oswald', sans-serif; */
  color: #999;
  overflow: hidden;
}

.personlich2 {
  width: 410px;
  height: 450px;
  margin-left: 538px;
  background-color: #292929;
  margin-top: 55px;
  position: absolute;
  color: #999;
}

.themen {
  width: 170px;
  height: 450px;
  margin-left: 0px;
  margin-right: 3px;
  background-color: #292929;
  margin-top: 70px;
  position: absolute;
}

.bilder {
  width: 830px;
  height: 750px;
  margin-left: 173px;
  margin-right: 0px;
  background-color: #292929;
  margin-top: 65px;
  position: absolute;
}

.current {
  color: #666 !important;
}

/* LINKS */
a:link {
  color: #999;
  text-decoration: none;
}

a:visited {
  color: #999;
  text-decoration: none;
}

a:hover {
  color: #999;
  text-decoration: underline;
}

a:active {
  color: #666;
  text-decoration: underline;
}

.rubi {
  margin-left: 30px;
}

.custom-links {
  font-family: 'OswaldRegular';
  font-weight: 400;
  text-align: justify;
  padding-left: 130px;
}

.custom-links a {
  margin-right: 6%;
  text-decoration: none;
  color: #999;
}

.custom-links a:hover {
  color: #999;
  text-decoration: underline;
}

.modal {
  display: none; /* Hide the modal by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}

/* Close button style */
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.hidden {
  display: none;
}